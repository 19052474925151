import { ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import {
  Checkmark24,
  CategoryNew24,
  TrashCan24,
  Cut24,
  Copy24,
  Paste24,
  MisuseOutline24,
  TagGroup24,
  Group24,
  Tree24,
  Help24,
  Debug24,
  ThumbsUp24,
  ThumbsDown24,
  Chemistry24,
  CaretDown16,
  ListChecked24,
  Attachment24,
  TreeView24,
  View24,
  ViewOff24,
  CategoryAdd24,
  DataView24,
  Category24,
  Cognitive24,
} from '@carbon/icons-react';
import ReactTooltip from 'react-tooltip';
import { _ } from 'utils';
import { useLocation } from 'react-router';
import { assignTag, removeTag } from 'Sync';
import aiSrc from './svgs/ai.svg';
import aiSrc2 from './svgs/ai2.svg';

import { Select } from './components';
import type { BaseOption } from './components';

const CategorizeIcon = props => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62775 1.31662C3.23589 1.66678 3.19099 1.95428 3.34533 3.12369C3.42366 3.71755 3.4145 3.75804 3.20191 3.75804C2.78578 3.75804 2.34681 4.07136 2.2185 4.46002C2.10068 4.81702 2.12837 5.19377 2.38377 6.70915C2.44124 7.05048 2.42756 7.06769 2.09715 7.06769C1.7161 7.06769 1 7.6727 1 7.99473C1 8.44539 2.89323 20.0039 3.00841 20.2568C3.28984 20.8743 3.04239 20.8579 12.0322 20.8579C21.0325 20.8579 20.7741 20.8753 21.0586 20.2509C21.255 19.8198 23.0768 8.20379 22.9975 7.88804C22.9071 7.52784 22.3434 7.06769 21.9925 7.06769C21.7155 7.06769 21.7112 7.05578 21.7837 6.4885C21.8243 6.1699 21.8879 5.57736 21.9248 5.17159C21.9899 4.45649 21.9815 4.42339 21.6541 4.09596C21.445 3.8869 21.2033 3.75804 21.0202 3.75804H20.7242L20.8018 2.77761C20.8877 1.6915 20.7795 1.37112 20.2444 1.12731C20.0414 1.03486 17.7776 1 11.9736 1H3.98222L3.62775 1.31662ZM19.126 2.95821C19.1004 3.12513 19.0566 3.37335 19.0288 3.50982L18.9782 3.75804H12.0445H5.11081L5.04241 3.34434C5.00479 3.11685 4.97346 2.86863 4.97279 2.79273C4.9718 2.68384 6.46456 2.65483 12.072 2.65483H19.1725L19.126 2.95821ZM20.1488 6.0823C20.1071 6.45044 20.0457 6.82278 20.0124 6.90971C19.9605 7.04475 18.8042 7.06769 12.0326 7.06769H4.11361L4.05337 6.70915C4.02028 6.512 3.96081 6.13967 3.9211 5.88173L3.84895 5.41287H12.0367H20.2244L20.1488 6.0823ZM21.2992 8.7704C21.2992 8.79665 20.9269 11.1382 20.4718 13.9738C20.0167 16.8093 19.6444 19.1459 19.6444 19.1662C19.6444 19.1865 16.2189 19.2031 12.0322 19.2031C7.84546 19.2031 4.41997 19.1865 4.41997 19.1662C4.41997 19.1459 4.04764 16.8093 3.59256 13.9738C3.13748 11.1382 2.76515 8.79665 2.76515 8.7704C2.76515 8.74403 6.93531 8.72252 12.0322 8.72252C17.129 8.72252 21.2992 8.74403 21.2992 8.7704Z"
        fill="currentColor"
      />
    </svg>
  );
};

const NodePopoverButton = ({
  icon,
  onClick,
  text,
  disabled,
  hidden,
  ...props
}: {
  icon: ReactNode;
  className?: string;
  onClick?: (e: any) => void;
  text: string;
  disabled?: boolean;
  hidden?: boolean;
}) => {
  return (
    <>
      <button
        data-tip={text}
        data-place={'bottom'}
        data-delay-show={250}
        className={classNames(
          'bg-transparent pa2 justify-center items-center',
          {
            dn: hidden,
            flex: !hidden,
            'white pointer dim': !disabled,
            'gray not-allowed': disabled,
          },
          props.className,
        )}
        onClick={e => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {icon}
      </button>
      <ReactTooltip />
    </>
  );
};

const popoverStyle = state => {
  const coords = state.getIn(['tree', 'view', 'selected', 'coordinates']);
  const isSelect = state.getIn(['tree', 'view', 'selected', 'type']) === 'SELECT';
  const yGapPx = 80;
  const treeUUID = state.getIn(['tree', 'uuid']);
  const halfBarLengthPx = state.getIn(['trees', treeUUID, 'methodology']) === 'PROACT' ? 13 * 18 : 138;

  if (coords && isSelect) {
    return {
      top: coords.get('y1') - yGapPx,
      left: (coords.get('x1') + coords.get('x2')) / 2 - halfBarLengthPx,
    };
  } else {
    return { display: 'none' };
  }
};

const NodePopoverButtonWithDropdown = ({
  hidden,
  state,
  dispatch,
  disabled,
  methodology,
  text,
  nodeType,
}: {
  hidden?: boolean;
  state: any;
  dispatch: any;
  disabled?: boolean;
  methodology: string;
  text: string;
  nodeType: string;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const path = state.getIn(['tree', 'view', 'selected', 'path']);

  const onClickHandler = nodeType => () => {
    dispatch(Map({ type: 'ASSIGN_TYPE', nodeType, path }));
    dispatch(Map({ type: 'MARK_POSSIBLE', path }));
  };

  return (
    <div
      className={classNames('pa2 justify-center items-center relative', {
        dn: hidden,
        'flex white': !hidden,
      })}
      onMouseEnter={() => {
        setDropdown(!disabled);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
    >
      <div
        className={classNames('flex justify-center items-center relative', {
          'gray not-allowed': disabled,
        })}
      >
        <TagGroup24 />
        <CaretDown16 className={'absolute top-0'} style={{ right: '-10px' }} />
      </div>

      <div
        className={classNames('bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray': dropdown,
        })}
        style={{ top: '2.5rem' }}
      >
        <NodePopoverButton
          icon={<Help24 />}
          text={methodology === 'PROACT' ? 'Hypothesis' : text}
          onClick={onClickHandler(methodology === 'PROACT' ? 'hypothesis' : nodeType)}
        />
        <NodePopoverButton
          icon={<Debug24 />}
          text={'Contributing Factor'}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('contributingFactor')}
        />
        <NodePopoverButton
          icon={<Chemistry24 />}
          text={'Physical Root'}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('physical')}
        />
        <NodePopoverButton
          icon={<Group24 />}
          text={'Human Root'}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('human')}
        />
        <NodePopoverButton
          icon={<Tree24 />}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('systemic')}
          text={'Systemic Root'}
        />
        <NodePopoverButton
          icon={<ListChecked24 />}
          onClick={onClickHandler('correctiveAction')}
          text={'Corrective Action'}
        />
      </div>
    </div>
  );
};

const CategorizeNodePopoverButtonWithDropdown = ({
  onSelectOption,
  onDeselectOption,
  nodeOid,
  categories,
  nodeType,
  treeCategories,
  // selectedCategories,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const selectedCategories = treeCategories.toJS
    ? treeCategories.toJS().filter(c => c.nodeUuid === nodeOid)
    : treeCategories.filter(c => c.nodeUuid === nodeOid);
  const selectedCategoryIds = selectedCategories?.map(c => c.tagUuid);

  const availableOptions = useMemo(() => {
    const tags = categories[nodeType];
    return tags ? tags : [];
  }, [categories, nodeType]);

  const options = availableOptions
    .filter(cat => !selectedCategoryIds.includes(cat.tagUuid))
    .map(category => ({ value: category.tagUuid, label: category.tagName }));

  const selectedOptions = selectedCategories.toJS
    ? selectedCategories.toJS().map(category => ({ label: category.tagName, value: category.tagUuid }))
    : selectedCategories.map(category => ({ label: category.tagName, value: category.tagUuid }));

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isDebugging = params.get('debug') === 'true';

  return (
    <div
      className={classNames('pa2 justify-center items-center relative flex')}
      onMouseEnter={() => {
        setDropdown(true);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
      onMouseOver={() => {
        setDropdown(true);
      }}
    >
      <div className={classNames('flex justify-center items-center relative')}>
        <CategorizeIcon className="white" />
        <CaretDown16 className="white absolute" style={{ right: -15, top: '0px' }} />
      </div>

      <div
        className={classNames('pa3 w6 bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray column flex-column': dropdown,
        })}
        style={{ top: '2.5rem' }}
      >
        {isDebugging ? (
          <div className="f7 debug-pane">
            <strong className="pb1 white">Info</strong>
            <div className="flex white">
              <span className="pr1">node:</span>
              <span>{nodeOid}</span>
            </div>

            <div className="flex white">
              <span className="pr1">type:</span>
              <span>{nodeType}</span>
            </div>

            <div className="pt2 white">
              <strong>Selected Categories</strong>
              {selectedCategories.map(cat => (
                <p>{cat.tagName}</p>
              ))}
            </div>
          </div>
        ) : null}
        {/* @ts-ignore */}
        <Select<BaseOption, true, GroupBase<any>>
          styles={{
            multiValue: provided => ({
              ...provided,
              transition: 'all 125ms ease-in-out',
              cursor: 'pointer',
            }),
            multiValueLabel: provided => ({
              ...provided,
              fontSize: '0.88rem',
              color: 'white',
              backgroundColor: '#0a0a0a',
              letterSpacing: '0.1px',
              borderRight: 'solid rgba(255, 255, 255, 0.1) 1px',
              paddingRight: 5,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }),
            multiValueRemove: provided => ({
              ...provided,
              color: 'white',
              backgroundColor: '#0a0a0a',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              padding: '0 3px',
              '&:hover': {
                backgroundColor: 'black',
              },
              '&:hover svg': {
                color: 'red',
              },
            }),
          }}
          placeholder="Select Category"
          className="w-100 w100"
          isDark
          isOptionSelected={option => selectedCategoryIds.includes(option.value)}
          hideSelectedOptions={false}
          noOptionsMessage={() => 'No categories'}
          isMulti
          // @ts-ignore
          defaultValue={selectedOptions}
          value={selectedOptions}
          selected={selectedCategoryIds}
          closeMenuOnSelect={false}
          menuShouldScrollIntoView
          blurInputOnSelect={false}
          isClearable
          onChange={(_, meta) => {
            switch (meta.action) {
              case 'deselect-option':
              case 'pop-value':
              case 'clear':
              case 'remove-value': {
                if (meta.removedValues?.length) {
                  meta.removedValues.forEach(opt => onDeselectOption({ node: nodeOid, tag: opt.value }));
                  break;
                }

                onDeselectOption({ node: nodeOid, tag: meta.removedValue.value });
                break;
              }
              case 'select-option': {
                onSelectOption({ node: nodeOid, tag: meta.option.value });
                break;
              }
              default:
                break;
            }
          }}
          options={options}
          controlShouldRenderValue
          isSearchable={options.length > 5}
        />
      </div>
    </div>
  );
};

const ShowHideNodePopoverButtonWithDropdown = ({ hidden, state, dispatch }) => {
  const [dropdown, setDropdown] = useState(false);
  const path = state.getIn(['tree', 'view', 'selected', 'path']);

  return (
    <div
      className={classNames('pa2 justify-center items-center relative flex white')}
      onMouseEnter={() => {
        setDropdown(true);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
      onMouseOver={() => {
        setDropdown(true);
      }}
    >
      <div className={classNames('flex justify-center items-center relative')}>
        <DataView24 />
        <CaretDown16 className={'absolute top-0'} style={{ right: '-10px' }} />
      </div>

      <div
        className={classNames('bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray': dropdown,
        })}
        style={{ top: '2.5rem' }}
      >
        <NodePopoverButton
          text={'Show'}
          icon={<View24 />}
          onClick={async () => {
            await dispatch(
              Map({
                type: 'SET_HIDDEN',
                path,
                hide: !hidden,
                toggleAll: false,
              }),
            );
            await dispatch(Map({ type: 'FIX_LAYOUT' }));
          }}
        />
        <NodePopoverButton
          text={'Show All'}
          icon={<Category24 />}
          onClick={async () => {
            await dispatch(
              Map({
                type: 'SET_HIDDEN',
                path,
                hide: !hidden,
                toggleAll: true,
              }),
            );
            await dispatch(Map({ type: 'FIX_LAYOUT' }));
          }}
        />
      </div>
    </div>
  );
};

const NodePopoverDivider = props => {
  return (
    <div
      className={classNames('bg-white-30 h2', props.className, {
        mh2: !props.className?.includes('mh') && !props.className?.includes('ml') && !props.className?.includes('mr'),
      })}
      style={{ width: '1px' }}
    />
  );
};

export const NodePopover = ({
  state,
  dispatch,
  isViewOnly,
  tags,
}: {
  state: any;
  dispatch: any;
  isViewOnly: boolean;
  tags: Record<string, any>[];
}) => {
  const token = state.get('token');
  const username = state.get('username');
  const user = state.getIn(['users', username]);
  const aiFeatureEnabled = user.getIn(['orgFeatures', 'aiFeatures'], false);
  const style = popoverStyle(state);
  const treeUUID = state.getIn(['tree', 'uuid']);
  const methodology = state.getIn(['trees', treeUUID, 'methodology']);
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());

  const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
  const categories = useMemo(() => {
    return {
      human: tags.filter(t => t.tagType.includes('Human')),
      systemic: tags.filter(t => t.tagType.includes('Systemic')),
      physical: tags.filter(t => t.tagType.includes('Physical')),
      failureEvent: tags.filter(t => t.tagType.includes('FailureEvent')),
      correctiveAction: tags.filter(t => t.tagType.includes('CorrectiveAction')),
      failureMode: tags.filter(t => t.tagType.includes('FailureMode')),
    };
  }, [tags]);
  const nodeType = node.get('type');
  const shouldRenderCategorySelect = useMemo(() => {
    // return true;
    // if (nodeType === 'failureEvent') {
    //   console.warn('node', node);
    //   // debugger;
    // }
    if (nodeType !== 'hypothesis') {
      return true;
    }
    // if (nodeType === 'physical' || nodeType === 'human' || nodeType === 'systemic') {
    //   return true;
    // }

    return false;
  }, [node, nodeType]);
  const treeCategories = state.getIn(['tree', 'categories'], List());
  // const selectedCategories = useMemo(() => {
  //   console.warn('re-running selected categories', {

  //     totalCategories: treeCategories.length,
  //     totalNodeCategories: treeCategories.filter(c => c.nodeUuid === node.get('oid')).length,
  //     categories: treeCategories,
  //     nodeCategories: treeCategories.filter(c => c.nodeUuid === node.get('oid'))
  //   })
  //   return treeCategories.filter(c => c.nodeUuid === node.get('oid'))
  // }, [treeCategories])

  const children = node.get('children');
  const nodeIsHidden = node.get('hidden');
  const childIsHidden =
    children && !children.isEmpty() ? children.filter(ch => ch.get('hidden')).size > 0 : node.get('hidden');
  const isHidden = node.get('hidden');
  const isPossible = node.get('possible');
  const isVerified = node.get('verified');
  const isFailureEvent = path.size < 2;
  const isTopBox = path.size < 4;
  const showCorrectiveActions = path.size < 2;

  async function removeCauseCategory({ node, tag }: { node: string; tag: string }) {
    // const categories = state.getIn(['tree', 'categories'], [])

    try {
      const results = await removeTag({ nodeUuid: node, tagUuid: tag, treeUuid: treeUUID, token });

      if (!results || !results.ok) {
        throw new Error('Failed to remove category');
      }

      // const updatedCategories = categories.filter(c => {
      //   return (c.tagUuid !== tag && c.nodeUuid !== node)
      // })
      dispatch({
        type: 'REMOVE_NODE_CATEGORY',
        payload: {
          node,
          category: tag,
          tree: treeUUID,
        },
      });

      // dispatch({
      //   type: 'UPDATE_TREE_CATEGORIES',
      //   meta: {
      //     operation: 'remove'
      //   },
      //   payload: {
      //     categories: updatedCategories,
      //     node
      //   }
      // })
    } catch (error) {
      console.error(error);
    }
  }

  async function addCauseCategory({ node, tag }: { node: string; tag: string }) {
    // const categories = state.getIn(['tree', 'categories'], [])

    try {
      const results = await assignTag({ nodeUuid: node, tagUuid: tag, treeUuid: treeUUID, token });

      if (!results) {
        throw new Error('Failed to assign tag');
      }

      // const updatedCategories = uniqBy([...categories, results], ['tagUuid', 'nodeUuid'])

      dispatch({
        type: 'ADD_NODE_CATEGORY',
        payload: {
          node,
          category: results,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getAISuggestions() {
    const treeUuid = treeUUID;
    const nodeUuid = node.get('oid');

    dispatch(Map({ type: 'SHOW_TOAST', message: 'Generating suggestions...' }));

    const results = await fetch(`/api/v0/suggestNextNode/${treeUuid}/${nodeUuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results) {
      dispatch(Map({ type: 'SHOW_TOAST', message: 'Error getting suggestions.', style: 'ERROR' }));
      return;
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[getAISuggestions, ${json.status}]: ${json.error}`);
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: json.error,
          style: 'ERROR',
        }),
      );
      return;
    }

    dispatch(Map({ type: 'HIDE_TOAST' }));

    const data = json.data;
    dispatch(Map({ type: 'PROCESS_SUGGESTIONS', path, data }));
  }

  async function getAISuggestionsTurbo() {
    const treeUuid = treeUUID;
    const nodeUuid = node.get('oid');

    dispatch(Map({ type: 'SHOW_TOAST', message: 'Generating tree...' }));

    const results = await fetch(`/api/v0/suggestNextNodeTurbo/${treeUuid}/${nodeUuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results) {
      dispatch(Map({ type: 'SHOW_TOAST', message: 'Error getting suggestions.', style: 'ERROR' }));
      return;
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[getAISuggestionsTurbo, ${json.status}]: ${json.error}`);
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: json.error,
          style: 'ERROR',
        }),
      );
      return;
    }

    dispatch(Map({ type: 'HIDE_TOAST' }));

    const data = json.data;
    dispatch(Map({ type: 'OPEN_TREE', treeUuid }));
  }

  const hasClipboard = state.get('clipboard', Map()).size === 0;
  const i18n = state.get('i18n');

  return (
    <div style={style} className="pa1 absolute flex justify-center items-center z-1 bg-dark-gray">
      <NodePopoverButton
        text={_(i18n, 'Add Child')}
        icon={<CategoryNew24 />}
        disabled={isHidden || isViewOnly}
        onClick={() => {
          dispatch(Map({ type: 'CREATE_CHILD', path }));
        }}
      />
      {aiFeatureEnabled && (methodology == 'PROACT' || methodology == '5WHYS') && (
        <NodePopoverButton
          text={_(i18n, 'AI Suggest Node')}
          icon={<img src={aiSrc} style={{ minWidth: 24, width: 24 }} />}
          disabled={isHidden}
          onClick={getAISuggestions}
        />
      )}
      {false && aiFeatureEnabled && (methodology == 'PROACT' || methodology == '5WHYS') && (
        <NodePopoverButton
          text={_(i18n, 'AI Suggest TURBO')}
          icon={<img src={aiSrc2} style={{ minWidth: 24, width: 24 }} />}
          disabled={isHidden}
          onClick={getAISuggestionsTurbo}
        />
      )}
      <NodePopoverButton
        text={_(i18n, 'Delete')}
        icon={<TrashCan24 />}
        disabled={isFailureEvent || isViewOnly}
        onClick={() => dispatch(Map({ type: 'DELETE_SUBTREE', path }))}
      />
      <NodePopoverDivider />
      <NodePopoverButton
        text={_(i18n, 'Cut')}
        icon={<Cut24 />}
        disabled={isFailureEvent || isHidden || isViewOnly}
        onClick={() => {
          dispatch(Map({ type: 'CUT_SUBTREE', path }));
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: 'Your logic has been cut.',
            }),
          );
        }}
      />
      <NodePopoverButton
        text={_(i18n, 'Copy')}
        icon={<Copy24 />}
        disabled={isFailureEvent || isHidden || isViewOnly}
        onClick={() => {
          dispatch(Map({ type: 'COPY_SUBTREE', path }));
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              toast: Map({ message: 'Your logic has been copied.' }),
            }),
          );
        }}
      />
      <NodePopoverButton
        text={_(i18n, 'Paste')}
        disabled={hasClipboard || isHidden || isViewOnly}
        icon={<Paste24 />}
        onClick={() => dispatch(Map({ type: 'PASTE_SUBTREE', path }))}
      />
      <div className={classNames({ dn: methodology !== 'PROACT' })}>
        <NodePopoverDivider />
      </div>

      <NodePopoverButtonWithDropdown
        dispatch={dispatch}
        state={state}
        methodology={methodology}
        text={node.get('id').slice(0, 1).toUpperCase() + node.get('id').slice(1, node.get('id').length)}
        nodeType={node.get('id')}
        disabled={
          (methodology !== 'PROACT' && showCorrectiveActions) ||
          (methodology === 'PROACT' && (isTopBox || isHidden)) ||
          isViewOnly
        }
      />

      {shouldRenderCategorySelect ? (
        <>
          {/* @ts-ignore */}
          <CategorizeNodePopoverButtonWithDropdown
            // onChange={(data, meta) => {
            //   if (!data && meta.action === 'clear') {
            //     removeCauseCategory({ node: node.get('oid'), tag: meta.removedValues[0].value });
            //   } else if (meta.action === 'select-option') {
            //     addCauseCategory({ node: node.get('oid'), tag: data.value });
            //   }
            // }}
            // if (!data && meta.action === 'clear') {
            //     removeCauseCategory({ node: node.get('oid'), tag: meta.removedValues[0].value });
            //   } else if (meta.action === 'select-option') {
            //     addCauseCategory({ node: node.get('oid'), tag: data.value });
            //   }
            treeCategories={treeCategories}
            onSelectOption={addCauseCategory}
            onDeselectOption={removeCauseCategory}
            nodeOid={node.get('oid')}
            // selectedCategories={selectedCategories}
            categories={categories}
            nodeType={nodeType}
          />
          <NodePopoverDivider className="ml3" />
        </>
      ) : null}

      {isVerified ? (
        <NodePopoverButton
          text={_(i18n, 'Mark Unverified')}
          icon={<MisuseOutline24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_HYPOTHETICAL', path }))}
        />
      ) : (
        <NodePopoverButton
          text={_(i18n, 'Mark Verified')}
          icon={<Checkmark24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_VERIFIED', path }))}
        />
      )}
      {isPossible ? (
        <NodePopoverButton
          text={_(i18n, 'Mark Not True')}
          icon={<ThumbsDown24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_NOT_TRUE', path }))}
        />
      ) : (
        <NodePopoverButton
          text={_(i18n, 'Mark True')}
          icon={<ThumbsUp24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_POSSIBLE', path }))}
        />
      )}
      {nodeIsHidden || childIsHidden ? (
        <ShowHideNodePopoverButtonWithDropdown
          dispatch={dispatch}
          hidden={nodeIsHidden ? nodeIsHidden : childIsHidden}
          state={state}
        />
      ) : (
        <NodePopoverButton
          text={_(i18n, 'Hide')}
          icon={<ViewOff24 />}
          disabled={isViewOnly}
          onClick={() =>
            dispatch(
              Map({
                type: 'SET_HIDDEN',
                path,
                hide: !nodeIsHidden,
                toggleAll: true,
              }),
            )
          }
        />
      )}
      <NodePopoverButton
        text={_(i18n, 'Connect')}
        icon={<TreeView24 className={'rotate-270'} />}
        disabled={isHidden || isViewOnly}
        onClick={() => {
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: "Please click on the node you'd like to connect, or on the canvas to cancel",
            }),
          );
          dispatch(Map({ type: 'CONNECT', node }));
        }}
      />
      <NodePopoverDivider />
      <NodePopoverButton
        text={_(i18n, 'Insert Template')}
        icon={<CategoryAdd24 />}
        disabled={isHidden || isViewOnly}
        onClick={() => {
          dispatch(
            Map({
              type: 'COPY_TEMPLATE_SUBTREE',
              treeUuid: treeUUID,
              path,
            }),
          );
          dispatch(Map({ type: 'SET_URL', url: '/templates' }));
        }}
      />
      <NodePopoverButton
        text={_(i18n, 'Attachments')}
        icon={<Attachment24 />}
        disabled={isHidden}
        onClick={() => dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'INFORMATION' }))}
      />
    </div>
  );
};
